<template>
    <section class="">
        <div class="row mx-0 h-100">
            <div class="col px-0 h-100 overflow-auto custom-scroll border-right br-l-10">
                <div v-if="id_promocion" class="row mx-0 text-general px-2 border-bottom align-items-center"
                     style="height:45px;"
                >
                    <div v-if="!promocion.id_cedi_promocion" class="col-auto px-1">
                        <p class=""> Promoción sin asignar fecha</p>
                    </div>
                    <div v-else class="mx-0 col">
                        <div class="row">
                            Promoción
                        </div>
                        <div class="row ">
                            <span class="bg-danger text-white br-10 f-13 d-middle-center px-2">
                                {{ promocion.text_estado }}
                            </span>
                        </div>
                    </div>
                    <div class="col ml-2">
                        <div class="row f-12">
                            {{ formatearFecha(promocion.inicio, 'DD MMM') }}
                        </div>
                        <div class="row f-12">
                            {{ formatearFecha(promocion.fin, 'DD MMM') }}
                        </div>
                    </div>
                    <div class="col-auto ml-auto px-0 d-middle">
                        <el-tooltip
                        v-show="id_promocion && $can('botones_cedis_promociones_gestionar') && edicionDisponible && detalle_promo.tipo == 3 || detalle_promo.tipo == 4 "
                        class="col-auto px-1" effect="light" content="Actualizar el precio de los productos con los descuentos aplicados " placement="bottom"
                        >
                            <div class="mx-1 btn-act" @click="actualizarPrecios">
                                <p class="mx-1 f-15">
                                    Actualizar precios
                                    <i class="icon-rotation f-17" />
                                </p>
                            </div>
                        </el-tooltip>
                        <el-tooltip
                        v-show="id_promocion && $can('botones_cedis_promociones_gestionar') && edicionDisponible"
                        class="item" effect="light" content="Editar vigencia y estado" placement="bottom"
                        >
                            <div class="btn-red" @click="modalEditarVigencia">
                                <i class="icon-calendar-edit f-17" />
                            </div>
                        </el-tooltip>
                        <el-tooltip v-show="id_promocion" class="item" effect="light" content="Detalle de la promocion"
                                    placement="bottom"
                        >
                            <div class="btn-action border ml-1">
                                <i class="icon-info-circled-alt text-gr-general f-18 cr-pointer"
                                   @click="condicionesPromo"
                                />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div v-show="id_promocion" class="row mx-0 px-3 justify-content-center mt-2">
                    <div class="bg-gr-prod col-11 br-10 d-middle-center">
                        <b class="mr-1">
                            {{ detalle_promo.pro_agregados }}
                        </b>
                    </div>
                </div>
                <div v-if="!mostrarMensaje()" class="p-2 f-15 overflow-auto custom-scroll"
                     style="height:calc(100vh - 243px);"
                >
                    <contentLoader v-if="mostrarLoaderDetalle()" />
                    <div v-for="(item, idx) in productos" :key="idx" class="row mx-0 justify-center">
                        <CardProductoPromocion :info="item" class="f-15 mb-3" @eliminar="eliminarProducto"
                                               @editar="editarPorcent"
                        />
                    </div>
                    <sinDatos v-if="mostrarMensaje()" icon="sale"
                              mensaje="No hay promociones seleccionadas para gestionar."
                    />
                    <div v-if="mostrarMensajeProductos()"
                         class="row mx-0 justify-content-center align-items-center h-100"
                    >
                        <div class="p-2 mx-2 f-15 col-10 text-center">
                            <img height="115" width="115" src="/img/no-imagen/mercado.svg" />
                            <p class="f-17">
                                Aún no has añadido productos a esta promoción, hazlo buscándolos en el panel
                                de la derecha
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width:425px;">
                <buscadorProductos ref="buscadorComponent" :class="{ 'disabled-div': !edicionDisponible }" />
            </div>
        </div>

        <!-- Partials -->
        <modalEliminar ref="modalEliminarProdu" titulo="Eliminar producto de la promoción"
                       mensaje="¿Desea descartar este producto?" @eliminar="accionRemoverProducto"
        />
        <modalEditarVigencia ref="modalEditarVigencia" @actualizar="actualizarVigencia" />
        <modalCondiciones ref="modalCondiciones" />
        <modal ref="modalEditarPorcent" :titulo="`${tipo_promocion === 2 ? 'Editar valor' : 'porcentaje'}`" icon="porcent"
               @guardar="editarProducto"
        >
            <div class="row m-3 justify-center text-center">
                <span v-if="tipo_promocion === 1 || tipo_promocion === 3 || tipo_promocion === 5">¿Desea Añadir este producto
                    a la promoción?</span>
                <div v-else-if="tipo_promocion === 2" class="px-4 col-10">
                    Indique el valor de rebaja del producto
                    <ValidationObserver ref="validacion">
                        <ValidationProvider v-slot="{ errors }" :rules="`required|max_value:${valor}`" name="valor">
                            <input-miles v-model="promo_valor" maxlength="8" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
                <div v-else-if="tipo_promocion === 4" class="px-4 col-10">
                    Indique el porcentaje de descuento del producto
                    <ValidationObserver ref="validacion2">
                        <ValidationProvider v-slot="{ errors }" :rules="`required|max_value:100`" name="descuento">
                            <el-input v-model="descuento" maxlength="8" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </ValidationObserver>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Promocion from '~/services/promociones/promociones_cedis_admin'


export default {
    components: {
        buscadorProductos: () => import('~/pages/cedis/ver/promociones/components/buscador_productos'),
        modalCondiciones: () => import('~/pages/promociones/partials/modalCondiciones'),
        modalEditarVigencia: () => import('~/pages/cedis/ver/promociones/partials/modalEditarVigencia')
    },
    data(){
        return {
            id_cedi: parseInt(this.$route.params.id_cedis),
            buscar: '',
            porcentaje: '',
            id_Producto_eliminar: null,
            id_producto: null,
            descuento: null,
            promo_valor: null,
            valor: null,
            rutas_admin: ['admin-vip.almacen.promociones', 'admin.almacen.promociones'],

        }
    },
    computed: {
        ...mapGetters({
            productos: 'promociones/promociones_cedis/productos',
            id_promocion: 'promociones/promociones_cedis/id_promocion',
            detalle_promo: 'promociones/promociones_cedis/detalle_promo',
            tipo_promocion: 'promociones/promociones_cedis/tipo_promocion',
            promo_cedis: 'promociones/promociones_cedis/promo_cedis',
            promocion: 'promociones/promociones_cedis/promocion',
            info_cedis: 'cedis/info_cedis',
        }),
        edicionDisponible(){
            return this.info_cedis.id_cedis_catalogo === null
        }

    },
    methods: {
        modalEditarVigencia(){
            this.$refs.modalEditarVigencia.toggle(this.promocion)
        },
        actualizarVigencia(data){
            this.listarPromociones()
            this.promocion.inicio = data.inicio
            this.promocion.fin = data.fin
            this.promocion.estado = data.estado
            this.promocion.id_cedi_promocion = data.id
        },
        eliminarProducto(id){
            this.id_Producto_eliminar = id
            this.$refs.modalEliminarProdu.toggle()
        },
        condicionesPromo(){
            this.$refs.modalCondiciones.toggle()
        },
        editarPorcent(producto){
            console.log('editarPorcent', producto);
            this.promo_valor = +(producto.promo_valor)
            this.id_producto = producto.id
            this.valor = producto.valor
            this.descuento = producto.promo_descuento
            this.$refs.modalEditarPorcent.toggle()
        },
        async accionRemoverProducto(){
            try {
                var data = null
                const enviar = {
                    id_producto: this.id_Producto_eliminar,
                    id_cedis: this.id_cedi,
                    id_promocion: this.id_promocion
                }
                var { data } = await Promocion.quitarProducto(enviar)
                this.notificacion('Mensaje', 'Producto descartado', 'success')
                const send = {
                    id_cedi: this.id_cedi,
                    id_promocion: this.id_promocion
                }
                this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', send)

                this.volverListarPromociones()
                if (this.$refs.buscadorComponent){
                    let productos_buscador = this.$refs.buscadorComponent.productos.length
                    if (productos_buscador > 0){
                        this.$refs.buscadorComponent.search() // si lo reseteo lista actumaticamente cuando elimina
                    }
                }
                this.$refs.modalEliminarProdu.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async listarPromociones(){
            try {
                const enviar = {
                    id_cedis: this.id_cedi
                }
                this.loading = true
                await this.$store.dispatch('promociones/promociones_cedis/action_listar_admin_promociones', enviar)
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarPrecios(){
            try {
                this.loading = true
                await Promocion.actualizarPreciosDescuento(this.id_cedi, this.id_promocion)
                const send = {
                    id_cedi: this.id_cedi,
                    id_promocion: this.id_promocion
                }
                this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', send)
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        async editarProducto(){
            try {
                var enviar = {
                    id_producto: this.id_producto,
                    id_promocion: this.id_promocion,
                    tipo: this.tipo_promocion,
                    id_cedis: this.id_cedi
                }
                var valid = false
                if (this.tipo_promocion === 2){ // edita
                    enviar.promo_valor = this.promo_valor
                    valid = await this.$refs.validacion.validate()
                    if (!valid){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                }
                if (this.tipo_promocion === 4){ // edita
                    enviar.descuento = this.descuento,
                    valid = await this.$refs.validacion2.validate()
                    if (!valid){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                }
                var data = null
                var { data } = await Promocion.agregarProductosAdmin(enviar)
                this.limpiar()
                this.notificacion('Mensaje', 'Producto editado', 'success')
                const send = {
                    id_cedi: this.id_cedi,
                    id_promocion: this.id_promocion,
                }
                this.$store.dispatch('promociones/promociones_cedis/action_listar_productos_agregados', send)
                this.volverListarPromociones()
                this.$refs.modalEditarPorcent.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        editarPromocion(){
            this.$refs.modalEditarPromo.toggle(this.detalle)
        },
        async actualizarMenuPromo(){
            try {
                this.volverListarPromociones()
            } catch (e){
                this.error_catch(e)
            }
        },

        volverListarPromociones(){
            this.listarPromociones()
        },

        limpiarPromocion(){
            const datos = {
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias: 1,
                promo_categorias: []
            }
            this.$store.commit('promociones/promociones_cedis/set_detalle_promo', datos)
            this.$store.commit('promociones/promociones_cedis/set_tiendas', [])
            this.$store.commit('promociones/promociones_cedis/set_productos', [])
        },
        limpiar(){
            this.descuento = null
            this.promo_valor = null
            this.valor = null
        },
        limpiarParcial(){
            const datos = {
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias: 1,
                promo_categorias: []
            }
            this.$store.commit('promociones/promociones_cedis/set_detalle_promo', datos)
            this.$store.commit('promociones/promociones_cedis/set_productos', [])

        },
        resetear(){
            this.$refs.buscadorComponent.resetearBuscador()
            this.limpiarParcial()
            this.buscar = ''
            this.porcentaje = ''
            this.id_Producto_eliminar = null
            this.id_producto = null
            this.descuento = null
            this.promo_valor = null
            this.valor = null

        },
        mostrarLoaderDetalle(){
            return this.loading_general || (this.id_promocion === null && this.promo_cedis.length == 0)
        },
        mostrarMensaje(){
            return !this.id_promocion && this.promo_cedis.length > 0
        },
        mostrarMensajeProductos(){
            return this.productos.length <= 0 && this.id_promocion && !this.loading_general
        }
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa {
	background: #f5f5f5;
}

.text-orange {
	color: #FF612B;
}

.text-morado {
	color: #9508EB;
}

.alto {
	height: calc(100vh - 68px);
}

.bg-gr-prod {
	background: linear-gradient(to right, #03D6BC20, #0D4DFF20)
}

.btn-act{
    height: 32px;
    width: 170px;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 8px !important;
    background-color: var(--color-general) !important;
    &:hover{
      box-shadow: 0 1px 4px #00000063;
    }
}
</style>
